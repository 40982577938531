<template>
  <div>
    <router-view style="margin-bottom: 40px"/>
    <Footer />
  </div>
</template>
<script>
import Footer from '@/components/Footer'
export default{
  components: {Footer}
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
