import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router';
import VueCookie from 'vue-cookies'
import {axiosPost} from "@/utils/axiosWrapper";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: null,
    loggingIn: false,
    loginError: null,
    level: 0
  },
  mutations: {
    loginStart: state => state.loggingIn = true,
    loginStop: (state, errorMessage) => {
      state.loggingIn = false;
      state.loginError = errorMessage;
    },
    updateAccessToken: (state, data) => {
      state.accessToken = data[0];
      state.level = data[1];
    },
    logout: (state) => {
      state.accessToken = null;
      state.level = 0;
    }
  },
  actions: {
    doLogin({ commit }, loginData) {
      commit('loginStart');
      axiosPost('/users/validate',{
        email: loginData.email.toLowerCase(),
        password: loginData.password
      },)
        .then(response => {
          const level =  response.data.user.settings.find(e => e.name === 'admin').value
          const isAdmin = level >= 1
          if(response.data.result && isAdmin) {
            VueCookie.set('accessToken', response.data.result);
            VueCookie.set('level', level);
            commit('loginStop', null);
            commit('updateAccessToken', [response.data.result, level]);


            VueCookie.set('email', loginData.email, '2m');
            if (loginData.rememberMeChecked) {
              VueCookie.set('password', loginData.password, '1m');
            } else {
              VueCookie.remove('password');
            }
            router.push({ name: 'Insights'});
          }else{
            if(response.data.error) commit('loginStop', response.data.error);
            else commit('loginStop', "You dont have admin privileges. Please request them at 'whyAmINotAdmin@kaiser.fyi'");
            commit('updateAccessToken', [null, 0]);
          }
        })
        .catch(error => {
          commit('loginStop', error.response.data.error);
          commit('updateAccessToken', [null, 0]);
        })
    },
    fetchAccessToken({ commit }) {
      commit('updateAccessToken', [VueCookie.get('accessToken'), VueCookie.get('level')]);
    },
    logout({ commit }) {
      VueCookie.remove('accessToken');
      VueCookie.remove('level');
      commit('logout');
      router.push('/Login');
    }
  }
})
