<template>
  <div class="legal">
    <div>
      <router-link to="/legal">Legal / Impressum</router-link>
    </div>
    <div>
      Copyright 2021 - {{ new Date().getFullYear() }}
    </div>
    <div>
      Version {{version}}
    </div>
  </div>
</template>
<script>
import {version} from '../../package'

export default {
  data() {
    return {
      version: version
    }
  }
}
</script>
<style scoped>
.legal{
  width: 90%;
  position: fixed;
  bottom: 0;
  margin: 10px 5%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
</style>
