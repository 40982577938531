import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    component:  () => import('../views/Dashboard.vue'),
    children: [
      {
        path: 'insights',
        name: 'Insights',
        meta: {
          levelRequired: 10
        },
        component:  () => import('../views/Dashboard/Insights.vue'),
      },
      {
        path: 'insights/matching',
        name: 'Matching Insights',
        meta: {
          levelRequired: 10
        },
        component:  () => import('../views/Dashboard/MatchingInsights.vue'),
      },
      {
        path: 'insights/referral',
        name: 'Referral',
        meta: {
          levelRequired: 10
        },
        component:  () => import('../views/Dashboard/Referral.vue'),
      },
      {
        path: 'insights/articles',
        name: 'Article Insights',
        meta: {
          levelRequired: 10
        },
        component:  () => import('../views/Dashboard/ArticleInsights.vue'),
      },
      {
        path: 'cms/translate',
        name: 'Translate',
        meta: {
          levelRequired: 1
        },
        component:  () => import('../views/Dashboard/Translate.vue'),
      },
      {
        path: 'cms/content',
        name: 'Content',
        meta: {
          levelRequired: 1
        },
        component:  () => import('../views/Dashboard/Content.vue'),
      },
      {
        path: 'cms/challenge',
        name: 'Challenge',
        meta: {
          levelRequired: 1
        },
        component:  () => import('../views/Dashboard/Challenge.vue'),
      },
      {
        path: 'cms/recommendation',
        name: 'Recommendation',
        meta: {
          levelRequired: 5
        },
        component:  () => import('../views/Dashboard/Recommendation.vue'),
      },
      {
        path: 'cms/knowledge',
        name: 'Knowledge',
        meta: {
          levelRequired: 1
        },
        component:  () => import('../views/Dashboard/Content.vue'),
      },
      {
        path: 'cms/files',
        name: 'Files',
        meta: {
          levelRequired: 1
        },
        component:  () => import('../views/Dashboard/Files.vue'),
      },
      {
        path: 'cms/team',
        name: 'Team',
        meta: {
          levelRequired: 5
        },
        component:  () => import('../views/Dashboard/Team.vue'),
      },
      {
        path: 'mail',
        name: 'Mail',
        meta: {
          levelRequired: 10
        },
        component:  () => import('../views/Dashboard/Mail.vue'),
      },
      {
        path: 'table/others',
        name: 'Others Table',
        meta: {
          levelRequired: 5
        },
        component:  () => import('../views/Dashboard/OthersTable.vue'),
      },
      {
        path: 'table/reports',
        name: 'Reports',
        meta: {
          levelRequired: 5
        },
        component:  () => import('../views/Dashboard/ReportTable.vue'),
      },
      {
        path: '*',
        meta: {
          levelRequired: 0
        },
        redirect: () => store.state.level <10 ? 'cms/article' : 'insights',
      },
      {
        path: '',
        meta: {
          levelRequired: 0
        },
        redirect: () => store.state.level <10 ? 'cms/article' : 'insights',
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component:  () => import('../views/Login.vue'),
  },
  {
    path: '/signup',
    name: 'Signup',
    component:  () => import('../views/Signup.vue'),
  },
  {
    path: '/legal',
    name: 'Legal',
    component:  () => import('../views/Legal.vue'),
  },
  {
    path: '*',
    redirect: '/dashboard/insights',
  }
];

const protectedRoutes = /dashboard*/


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  store.dispatch('fetchAccessToken');
  if (to.name !== 'Login') {
    console.log(`Accessing protected area: ${to.name}`)
    if (store.state.accessToken == null) {
      if(to.fullPath.match(protectedRoutes)) {
        console.warn("Tried to access protected area without being logged in. Routing to sign-in page.")
        next(`/login` );
      }
    } else {
      if (to.meta.levelRequired > store.state.level){
        if(from.name==='Login') {
          console.warn('Too less rights, redirecting...')
          next({name: 'Knowledge'});
          return
        }

        alert("You just tried to access a protected area without having the necessary level.\n" +
          "If you think this is a mistake, reach out to Noah or Ruben.")
        next(from.fullPath);
        return;
      }
    }
  }
  next();
});

export default router



