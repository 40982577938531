import Vue from 'vue'

import App from './App.vue';
import router from './router';
import VueCookies from 'vue-cookies'
import store from './store';
import VueClipboard from 'vue-clipboard2'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';

Vue.use(VueCookies)
Vue.use(VueMaterial)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
