const SERVER_ADDRESS = 'https://api.mindsupport.eu';
// const SERVER_ADDRESS = 'http://localhost:3002';
const LOGGER = "https://log.mindsupport.eu"
const LOGGER_SALT = "54686973206973206e6f7420612073617665206b6579"
const MEDIA_LOCATION = `https://media.kaiser.fyi/mindsupport/${420*2}/`
const FILES_LOCATION = `https://files.kaiser.fyi/mindsupport/`

module.exports = {
  SERVER_ADDRESS,
  LOGGER,
  LOGGER_SALT,
  FILES_LOCATION,
  MEDIA_LOCATION
};
